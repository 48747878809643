<template>
  <div class="section2">
    <div id="toAnimateTitle" class="title">
      <!-- <span>作为欧洲家喻户晓的家清品牌</span>
      <span>U2每年为法国和国际上的700多家品牌客户</span>
      <span>提供各种类型的清洁产品</span> -->
      <span>1936年</span>
      <span>U2米歇尔实验室成立</span>
      <span>专业研究清洁产品和杀虫剂</span>
    </div>
    <div id="toAnimateTitle2" class="title2">
      <!-- <span>迄今为止，U2为空客、凡尔赛宫、卢浮宫等众多名流</span>
      <span>奉献了创新、高效、瑰丽的专业之作。</span> -->
      <span>U2自成立以来专业生产各种清洁产品</span>
      <span>作为国际化的家清品牌，在欧洲家喻户晓</span>
    </div>
    <div class="product">
      <img class="prdbg" src="./imgs/section2-bg.png" />
      <div class="prdlist">
        <div class="item" id="prdlist">
          <div class="card" v-for="(item, index) in showprdlist" :key="index">
            <img :class="item.class" :src="item.img" />
            <div>
              {{ item.name }}
              <img class="arrowright" src="./imgs/section2-arrow-right.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      prdlist: [
        {
          id: 1,
          name: "洗衣机清洁剂",
          img: require("./imgs/section2-item1.png"),
          class: "prdimg"
        },
        {
          id: 2,
          name: "洗衣机清洁剂",
          img: require("./imgs/section2-item2.png"),
          class: "prdimg"
        },
        {
          id: 3,
          name: "洗衣机清洁剂",
          img: require("./imgs/section2-item3.png"),
          class: "prdimg"
        },
        {
          id: 4,
          name: "洗衣机清洁剂",
          img: require("./imgs/section2-item5.png"),
          class: "prdimg"
        },
        {
          id: 5,
          name: "洗衣机清洁剂",
          img: require("./imgs/section2-item6.png"),
          class: "prdimg"
        },
        {
          id: 6,
          name: "洗衣机清洁剂",
          img: require("./imgs/section2-item7.png"),
          class: "prdimg"
        },
        {
          id: 7,
          name: "衣物除菌液（芳香型）",
          img: require("./imgs/section2-item8.png"),
          class: "prdimg"
        },
        {
          id: 8,
          name: "冰箱除菌除味剂",
          img: require("./imgs/section2-item9.png"),
          class: "prdimg"
        }
        
        // {id: 4, name: "空气清新剂", img: require("./imgs/section2-item4.png"), class: "prdimg3"},
      ],
      showprdlist: []
    };
  },
  watch: {},
  created() {
    for (let i = 0; i < 50; i++) {
      this.showprdlist = this.showprdlist.concat(this.prdlist);
    }
  },
  beforeDestroy() {},
  methods: {
    goAnimation() {
      var elementAni = document.getElementById("toAnimateTitle");
      elementAni.classList.add("animationtitle");

      var elementAniText = document.getElementById("toAnimateTitle2");
      elementAniText.classList.add("animationtitle");
    },
    removeAnimation() {
      var elementAni = document.getElementById("toAnimateTitle");
      elementAni.classList.remove("animationtitle");

      var elementAniText = document.getElementById("toAnimateTitle2");
      elementAniText.classList.remove("animationtitle");
    }
  }
};
</script>

<style lang="scss" scoped>
.section2 {
  position: relative;
  .title {
    opacity: 0;
    position: relative;
    font-size: 0.14rem;
    line-height: 0.25rem;
    padding-top: 0.7rem;
    padding-bottom: 0.15rem;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    margin: 0 3.1rem;
    box-sizing: border-box;
    display: grid;
    align-content: center;
    justify-content: center;
    justify-items: center;
    &::after {
      content: " ";
      position: absolute;
      bottom: 0;
      left: 1.5rem;
      width: 0.4rem;
      border-bottom: 1px solid #b7b7b7;
    }
    opacity: 0;
  }
  .title2 {
    opacity: 0;
    position: relative;
    font-size: 0.11rem;
    line-height: 0.18rem;
    padding-top: 0.15rem;
    padding-bottom: 0.2rem;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    margin: 0 3.1rem;
    box-sizing: border-box;
    display: grid;
    align-content: center;
    justify-content: center;
    justify-items: center;
    opacity: 0;
  }

  .animationtitle {
    animation: move-text 0.8s forwards;
  }

  @keyframes move-text {
    0% {
      transform: translateY(-40px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
    to {
      opacity: 1;
    }
  }

  .product {
    // background: url("./imgs/section2-bg.png") no-repeat;
    background-size: 100% 100%;
    position: relative;
    height: 3.5rem;

    .prdbg {
      width: 100%;
      position: absolute;
    }

    .prdlist {
      width: 60%;
      margin-left: 20%;
      overflow: hidden;

      .item {
        // margin: 0 1rem;
        // display: flex;
        // flex-direction: row;
        // flex-wrap: nowrap;
        // align-content: center;
        // justify-content: center;
        // align-items: center;
        display: inline-block;
        white-space: nowrap;
        animation: scrollLeft 3600s linear infinite;

        .card {
          background: #f7f7f7;
          width: 250px;
          height: 2rem;
          position: relative;
          font-size: 0.07rem;
          // margin-left: 2.5%;
          margin-top: 0.5rem;
          display: inline-block;
          margin-right: 0.2rem;

          .prdimg {
            width: 56%;
            margin-top: 0.4rem;
            left: 22%;
            position: absolute;
          }

          .prdimg3 {
            width: 100%;
            margin-top: 0.4rem;
            position: absolute;
          }

          div {
            bottom: 0;
            left: 0.1rem;
            right: 0.1rem;
            position: absolute;
            height: 0.2rem;
            line-height: 0.2rem;
            width: 86%;

            .arrowright {
              width: 0.2rem;
              float: right;
              margin-top: 0.08rem;
            }
          }
        }
      }
    }
  }

  /* 定义动画 */
  @keyframes scrollLeft {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(
        calc(-100% - 10px)
      ); /* 加上一些额外的空白，以便滚动到最后一个元素时不会立即切换 */
    }
  }
}
</style>
